label {
    font-family: 'Roboto', sans-serif; /* Use a modern sans-serif font */
}

p {
    font-family: 'Roboto', sans-serif; /* Use a modern sans-serif font */
}

a {
    /* Add your styles for a here */
    font-family: 'Roboto', sans-serif; /* Use a modern sans-serif font */
}

h1 {
    font-family: 'Roboto', sans-serif; /* Use a modern sans-serif font */
}

body {
    font-family: 'Roboto', sans-serif; /* Use a modern sans-serif font */
}