.search-bar-input .MuiInputBase-root {
    width: 400px; /* Change the width to the desired value */
    box-sizing: border-box;
    height: 40px;
    border: 1px solid #000; /* Change #000 to the color you want for the border */
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px; /* Add this line to make the corners rounded */
    font-family: inherit; /* Use the font from the parent element */
}
.search-bar-button {
    box-sizing: border-box;
    height: 40px;
    border: 1px solid #000; /* Change #000 to the color you want for the border */
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px; /* Add this line to make the corners rounded */
    font-family: inherit; /* Use the font from the parent element */
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.search-bar-button.MuiButton-containedPrimary {
    margin-top: 10px;
    font-family: inherit; /* Use the font from the parent element */
}